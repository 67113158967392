<template>
  <iframe src="https://library.alphaleaf.io/" frameborder="0" allowfullscreen></iframe>
</template>
<script>
// let includes = require("lodash/includes");
// import router from "../router";
// import { db } from "@/firebase";
// import { doc, getDoc } from "firebase/firestore";
// import Icon from "@/components/btn/Icon.vue";
// import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default {
  components: {
    // Icon,
  },
  data() {
    return {
      window: this.$store.state.main.window,
      user: this.$store.state.main.user,
      main: this.$store.state.main.layout.main,
      section: this.$store.state.main.layout.section,
    };
  },
};
</script>
<style lang="scss" scoped>
[el="header"] {
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
  height:0px;
}
[el="main"] {
  --bg-color: rgba(0, 0, 0, 0.85);
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;
}
.v-center {
  display: grid;
  align-items: center;
}
iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 0;
  left: 0;
  top: 0;
}
#mobileLogo {
  height: 35px;
}
.logo-symbol {
  fill: var(--color-ocean);
}
</style>
